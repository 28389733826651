import React from 'react';
import { logo } from './assets';
import "../assets/css/ForgotPassword.css";
import ForgotPasswordController, { Props } from './ForgotPasswordController';
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import i18n from '../../../components/src/TranslateLanguage';



export default class ForgotPassword extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  getValidationSchema = () => {
    return Yup.object().shape({
      email: Yup.string()
        .email(i18n.t('INVALID_EMAIL_ADDRESS'))
        .required(i18n.t('EMAIL_IS_REQUIRED')),
    });
  };
  render() {
    return (
      <>
        <div className="main-wrapper">
          <div className={`${this.state.currentInterfaceLanguage === "en" ? "signin-form-wrapper" :"signin-form-wrapper ar-row-reverse"}`}>
            <div className="col-6">
              <div className="form-image">
                <div className="login-image">
                  <img src={logo} alt="logo" width={300} />
                </div>
              </div>
            </div>
            <div className="col-6 forget-wrapper">
              <div className="close-btn">
                <Link to="/Signin">
                  <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512">
                    <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
                  </svg>
                </Link>
              </div>
              <div className="login-form-wrapper forget-block" dir={i18n.locale === 'ar'? 'rtl': 'ltr'}>

                <Formik
                  initialValues={{ email: '' }}
                  validationSchema={this.getValidationSchema()}
                  onSubmit={(values) => {
                    (values != null && values != undefined) &&
                    this.forgotPassword(values);
                  }}
                >
                  {({ handleSubmit }) => {
                    return <form onSubmit={handleSubmit}>
                      <div className="block-heading">

                        <h4>{i18n.t('FORGOT_PASSWORD')}</h4>
                        <p>{i18n.t('NO_WORRIES_WE_LL_SEND_YOU_RESET_INSTRUCTIONS')}</p>
                        
                      </div>
                      <div className="block-content">
                        <div className="form-group">

                          <label htmlFor="email">{i18n.t('YOUR_EMAIL')}</label>
                          <Field
                            type="email"
                            id="email"
                            name="email"
                            placeholder="Raffles@.com"
                          />
                          <span className="error-text" style={{ color: 'red' }}>
                            <ErrorMessage name="email" component="div" className="error" />
                          </span>
                        </div>
                        <div className="form-group">

                          <button type="submit" data-test-id="submitbutton" className="btn button mt-35">
                            {i18n.t('RESET_PASSWORD')}
                          </button>
                          <span className="or">{i18n.t('OR')}</span>
                          <Link to="/Signup" style={{ textDecoration: "none" }}>
                            <button className="btn button-outline">{i18n.t('SIGN_UP')}</button>
                          </Link>
                        </div>
                      </div>
                    </form>
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </div>

      </>
    )
  }
}
