import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import MergeEngineUtilities from "../../utilities/src/MergeEngineUtilities";
import toast from 'react-hot-toast';
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";
import i18nJs from "../../../components/src/TranslateLanguage";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  password: string;
  email: string;
  enablePasswordField: boolean;
  checkedRememberMe: boolean;
  placeHolderEmail: string;
  placeHolderPassword: string;
  imgPasswordVisible: any;
  imgPasswordInVisible: any;
  labelHeader: string;
  btnTxtLogin: string;
  labelRememberMe: string;
  btnTxtSocialLogin: string;
  labelOr: string;
  passwordError: string;
  emailError: string;
  selectedRole: string;
  inputVisible: boolean;
  checkboxcheck: boolean;
  initialvalue : {
    email: string;
    password: string;
    checkbox:boolean;
  };
  currentInterfaceLanguage: any;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountLoginController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiEmailLoginCallId: string = "";
  validationApiCallId: string = "";
  emailReg: RegExp;
  labelTitle: string = "";
  loginApiCallId: string = "";
  userDetailsApiId:any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      email: "",
      password: "",
      enablePasswordField: true,
      checkedRememberMe: false,
      placeHolderEmail: configJSON.placeHolderEmail,
      placeHolderPassword: configJSON.placeHolderPassword,
      imgPasswordVisible: configJSON.imgPasswordVisible,
      imgPasswordInVisible: imgPasswordInVisible,
      labelHeader: configJSON.labelHeader,
      btnTxtLogin: configJSON.btnTxtLogin,
      labelRememberMe: configJSON.labelRememberMe,
      btnTxtSocialLogin: configJSON.btnTxtSocialLogin,
      labelOr: configJSON.labelOr,
      emailError: "",
      passwordError: "",
      selectedRole: "Student",
      inputVisible: false,
      checkboxcheck: false,
      initialvalue: {
        email: "",
        password: "",
        checkbox:false
      },
      currentInterfaceLanguage: "",
    };

    this.emailReg = new RegExp("");
    this.labelTitle = configJSON.labelTitle;
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.callGetValidationApi();
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area Start
    let loginInfo = await getStorageData("loginInfo")
    let Role = await getStorageData("role")
    this.setState({selectedRole:Role || "Student"})
    if (loginInfo) {
      let parsedata = JSON.parse(loginInfo)
      this.setState({ initialvalue: parsedata })
    }
    const languageKey = await getStorageData("language")
    this.setState({currentInterfaceLanguage:languageKey})
    // Customizable Area End
  }

  // Customizable Area Start

  onCheckboxClick = () => {
    this.setState({ checkboxcheck : !this.state.checkboxcheck})
  }

  onClickRedirect = () => {
    setStorageData("role", this.state.selectedRole)
    this.props.navigation.navigate('EmailAccountLoginBlockWeb')
  }

  signUpRedirect = async() => {
    const selectRole = await getStorageData('role')
    selectRole === "Lecturer" && this.props.navigation.navigate("LecturerSignup")
    selectRole === "Student" && this.props.navigation.navigate("Signup")
  }

  onSignin = async (email:string,password:string,checkbox:boolean) => {
    if(email != "" && password != "" ){
      setStorageData("email", email);

      let attributes = {
        email: email,
        password: password,
        checkbox :checkbox 
      }

      if(!checkbox) {
        removeStorageData("loginInfo")
      }

      if (checkbox) {
        setStorageData("loginInfo", JSON.stringify(attributes));
      }

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

    this.loginApiCallId = requestMessage.messageId;
    const header = {
      "Content-Type": "application/json",
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    const selectRole = await getStorageData('role')

    const data = {
      "type": "email_account",
      "attributes": {
          "email":email,
          "password":password,
          "role_id": selectRole === "Student" ? 1 : 2
      } 
    };
    const httpBody = {
      data: data
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_login/login"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    
    runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  shortCode = (apiRequestCallId:any, responseJson:any, errorResponse:any) => {
    if (apiRequestCallId == null) {
      return;
    }
  
    if (apiRequestCallId === this.loginApiCallId) {
      this.handleLoginApiResponse(responseJson);
    } else if (apiRequestCallId === this.validationApiCallId) {
      this.handleValidationApiResponse(responseJson);
    }else if (apiRequestCallId === this.userDetailsApiId && responseJson?.data) {
      setStorageData("profile_image", responseJson?.data?.attributes?.profile_picture?.url)
      this.selectRoleNavigation()
    } else if (apiRequestCallId === this.apiEmailLoginCallId) {
      this.handleEmailLoginApiResponse(responseJson, errorResponse);
    }
  };

  handleLoginApiResponse = async (responseJson: any) => {
    
    const userLanguage = localStorage.getItem("language") || "en";
    i18nJs.locale = userLanguage;
  
    if (responseJson && responseJson.errors) {
      const errors = responseJson.errors[0];
  
      if (errors.failed_login) {
        const translatedMessage = i18nJs.t(errors.failed_login);
        toast.error(translatedMessage);
      }
  
      if (errors.account) {
        const translatedMessage = i18nJs.t(errors.account);
        toast.error(translatedMessage);
      }
    } else {
      this.getProfileDetails(responseJson?.meta?.token, responseJson?.meta?.id);
      setStorageData("token", responseJson?.meta?.token);
      setStorageData("user_id", responseJson?.meta?.id);
      // this.selectRoleNavigation();
    }
  };

  getProfileDetails = (token:string, userId:any) => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token:token
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.userDetailsApiId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "account_block/accounts/" + userId
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  selectRoleNavigation = async() => {
    const selectRole = await getStorageData("role")
    selectRole === "Lecturer" && this.props.navigation.navigate("MyCoursesLecturer");
    selectRole === "Student" && this.props.navigation.navigate("Categoriessubcategories");
  }
  
  handleValidationApiResponse = (responseJson:any) => {
    if (responseJson !== undefined) {
      let arrayHolder = responseJson.data;
      this.arrayHolder(arrayHolder);
    }
  };
  
  handleEmailLoginApiResponse = (responseJson:any, errorResponse:any) => {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
      this.saveLoggedInUserData(responseJson);
      this.sendLoginSuccessMessage();
      this.openInfoPage();
    } else {
      this.parseApiErrorResponse(responseJson);
      this.sendLoginFailMessage();
    }
  
    this.parseApiCatchErrorResponse(errorResponse);
  };
  arrayHolder = (arrayholder:any) =>{
    if (arrayholder && arrayholder.length !== 0) {
      let regexData = arrayholder[0];

      if (regexData && regexData.email_validation_regexp) {
        this.emailReg = new RegExp(regexData.email_validation_regexp);
      }
    }
  }

  async receive2(from: string, message:Message){
    if (getName(MessageEnum.ReciveUserCredentials) === message.id) {
      const userName = message.getData(getName(MessageEnum.LoginUserName));

      const password = message.getData(getName(MessageEnum.LoginPassword));

      const countryCode = message.getData(
        getName(MessageEnum.LoginCountryCode)
      );

      if (!countryCode && userName && password) {
        this.setState({
          email: userName,
          password: password,
          checkedRememberMe: true,
        });

        //@ts-ignore
        this.txtInputEmailProps.value = userName;

        //@ts-ignore
        this.txtInputPasswordProps.value = password;

        this.CustomCheckBoxProps.isChecked = true;
      }
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

     this.shortCode(apiRequestCallId, responseJson, errorReponse)
    }
  }

  btnSocialLoginProps = {
    onPress: () => this.goToSocialLogin(),
  };

  btnEmailLogInProps = {
    onPress: () => this.doEmailLogIn(),
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry =
        !this.state.enablePasswordField;
      this.btnPasswordShowHideImageProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  // Web Event Handling
  handleClickShowPassword = () => {
    this.setState({
      enablePasswordField: !this.state.enablePasswordField,
    });
  };

  setEmail = (text: string) => {
    this.setState({
      email: text,
    });
  };

  setPassword = (text: string) => {
    this.setState({
      password: text,
    });
  };

  setRememberMe = (value: boolean) => {
    this.setState({ checkedRememberMe: value });
  };

  CustomCheckBoxProps = {
    onChangeValue: (value: boolean) => {
      this.setState({ checkedRememberMe: value });
      this.CustomCheckBoxProps.isChecked = value;
    },
    isChecked: false,
  };

  btnForgotPasswordProps = {
    onPress: () => this.goToForgotPassword(),
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true,
  };

  btnPasswordShowHideImageProps = {
    source: imgPasswordVisible,
  };

  btnRememberMeProps = {
    onPress: () => {
      this.setState({ checkedRememberMe: !this.CustomCheckBoxProps.isChecked });
      this.CustomCheckBoxProps.isChecked = !this.CustomCheckBoxProps.isChecked;
    },
  };

  txtInputEmailWebProps = {
    onChangeText: (text: string) => {
      this.setState({ email: text });

      //@ts-ignore
      this.txtInputEmailProps.value = text;
    },
  };

  txtInputEmailMobileProps = {
    ...this.txtInputEmailWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputEmailProps = this.isPlatformWeb()
    ? this.txtInputEmailWebProps
    : this.txtInputEmailMobileProps;
   
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.receive2(from, message)
    // Customizable Area End
  }

  sendLoginFailMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginFaliureMessage));
    this.send(msg);
  }

  sendLoginSuccessMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginSuccessMessage));

    msg.addData(getName(MessageEnum.LoginUserName), this.state.email);
    msg.addData(getName(MessageEnum.CountyCodeDataMessage), null);
    msg.addData(getName(MessageEnum.LoginPassword), this.state.password);
    msg.addData(
      getName(MessageEnum.LoginIsRememberMe),
      this.state.checkedRememberMe
    );

    this.send(msg);
  }

  saveLoggedInUserData(responseJson: any) {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));

      msg.addData(
        getName(MessageEnum.SessionResponseData),
        JSON.stringify(responseJson)
      );
      msg.addData(
        getName(MessageEnum.SessionResponseToken),
        responseJson.meta.token
      );

      this.send(msg);
    }
  }

  openInfoPage() {
    // Merge Engine - Navigation - btnEmailLogIn - Start
    const msg: Message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
    // Merge Engine - Navigation - btnEmailLogIn - End
  }

  goToForgotPassword() {
    // Merge Engine - Navigation - btnForgotPassword - Start
    const msg: Message = new Message(
      getName(MessageEnum.NavigationForgotPasswordMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationForgotPasswordPageInfo), "email");
    this.send(msg);
    // Merge Engine - Navigation - btnForgotPassword - End
  }

  goToSocialLogin() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationSocialLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  doEmailLogIn(): boolean {
    if (
      this.state.email === null ||
      this.state.email.length === 0 ||
      !this.emailReg.test(this.state.email)
    ) {
      this.showAlert("Error", configJSON.errorEmailNotValid);
      return false;
    }

    if (this.state.password === null || this.state.password.length === 0) {
      this.showAlert("Error", configJSON.errorPasswordNotValid);
      return false;
    }

    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const attrs = {
      email: this.state.email,
      password: this.state.password,
    };

    const data = {
      type: "email_account",
      attributes: attrs,
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiEmailLoginCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  callGetValidationApi() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
}
