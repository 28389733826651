import React from 'react';
import OtpInput from 'react-otp-input';
import { logo } from './assets';
import EmailAccountRegistrationController, { Props } from './EmailAccountRegistrationController'
import { getStorageData } from '../../../framework/src/Utilities';
import i18n from '../../../components/src/TranslateLanguage';

export default class EmailAccountVerifyNumber extends EmailAccountRegistrationController {
    constructor(props: Props) {
        super(props);
    }
  render() {
    let phone_number = getStorageData("contact_no")
    return (
        <>
        <div className="main-wrapper" data-test-id='testid'>
            <div className="signin-form-wrapper align-center" id='fakid'>
                <div className="col-6">
                    <div className="form-image faqclass">
                        <div className="login-image" data-test-id='secondfak'>
                            <img src={logo} alt="logo" width={300} />
                        </div>
                    </div>
                </div>
                <div className="col-6 signup-item">
                    <div className="close-btn" id='checkid' style={{marginTop: "-30px"}}>
                        <span onClick={() => {this.props.navigation.navigate('Signup')}} >
                            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512">
                                <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
                            </svg>
                        </span>
                    </div>
                    <div className="login-form-wrapper fakehai" dir={i18n.locale === 'ar'? 'rtl': 'ltr'}>
                        <form>
                            <div className="block-heading">
                                <h4>{i18n.t('VERIFY_MOBILE_NUMBER')}</h4>
                                <p>{i18n.t('ENTER_THE_CODE_JUST_SENT_TO')} <span dir='ltr'>{this.state.fullContactNumber}</span> </p>
                            </div>
                            <div className="block-content">
                                <div className="form-group otp-block" dir='ltr'>
                                    <label htmlFor="code" dir={i18n.locale ==='ar' ? 'rtl': 'ltr'}>
                                        {i18n.t('ENTER_CODE')}
                                    </label>

                                    <OtpInput
                                        value={this.state.otp}
                                        inputType="tel"
                                        data-test-id="OtpField"
                                        shouldAutoFocus={true}
                                        onChange={(value:any) => this.setState({ otp: value})}
                                        numInputs={6}
                                        renderSeparator={<span></span>}
                                        renderInput={(props:any) => <input {...props} />}
                                        inputStyle={{
                                            width: "1em", 
                                            marginRight: "12px",
                                            opacity: "1",
                                            minWidth: "66px",
                                            height: "80px",
                                            borderRadius: "10px",
                                            textAlign: "center",
                                            border: "1px solid rgb(0, 0, 0, 0.2)",
                                            background: "rgb(255, 255, 255)",
                                            fontSize: "24px",
                                        }} />

                                </div>
                                <span className='send-span'>{i18n.t('DIDNT_GET_CODE')}
                                    <a href="#">{i18n.t('SEND_AGAIN')}</a>
                                </span>
                                <a href="#">{i18n.t('CALL_ME_INSTEAD')}</a>
                                <div className="form-group">
                                    <button onClick={this.handleOtpVerify} data-test-id="handleOtpVerifyid" type="button" className="btn button  mt-35" >{i18n.t('VERIFY_MOBILE_NUMBER_BUTTON')}</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

    </>
    )
  }
}
