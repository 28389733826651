import React from "react";

// Customizable Area Start
import { Box, Typography, Grid, withStyles, ThemeProvider, Paper, createTheme, Card, TextField, styled, Button, IconButton } from "@material-ui/core";
import CircularProgress from '@material-ui/core/CircularProgress';
import NavigationMenu from "../../dashboard/src/NavigationMenu.web";

// Customizable Area End

import AssessmenttestResultController, {
    Props
} from "./AssessmenttestResultController";
import i18nJs from "../../../components/src/TranslateLanguage";
const baseURL = require("../../../framework/src/config.js").baseURL

const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff"
        }
    }
});

export class AssessmenttestList extends AssessmenttestResultController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderDataList = () => {
      return this.resulListtarray().length ? (
        this.resulListtarray().map((object: any, index: number) => {
            const questions = object.attributes.questions.data
            const course = object.attributes.course
            const assessments = object.attributes.Assessments
            return (
                <Grid item xs={12} key={index}>
                    <Card elevation={0} className="session-card">
                        <Grid container justifyContent="space-between" alignItems="flex-end" style={{padding:"24px"}}  spacing={2}>
                            <Grid item xs={12} md={8} >
                                <Box className="image-des-contanet">
                                    <Grid item >
                                        <Box className="image-des-contanet-images">
                                            {assessments.course_image ?  <img src={baseURL + assessments.course_image} className="session-image" style={{borderRadius: "8px"}} /> :
                                            <div className="session-image" style={{textAlign: "center",  border: "0.1px solid gray", borderRadius: "8px" }}>
                                                {i18nJs.t('No image available')}
                                            </div>
                                            }
                                        </Box>
                                    </Grid>
                                    <Grid item>
                                        <Box>
                                            <Typography className="session-course-by margin-03">{i18nJs.t('A Course by')} {object.attributes.account.first_name}.</Typography>
                                            <Typography className="session-course-title margin-03">{assessments.name}</Typography>
                                            <Typography className="session-course-information">{course.course_name}</Typography>
                                            <Box className="icon-container-main">
                                                <Box className="icon-container">
                                                    <Box>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                            <path fillRule="evenodd" clipRule="evenodd" d="M5.97496 3.23397L3.41663 5.3673C3.06663 5.65897 2.54163 5.6173 2.24996 5.25897C1.94996 4.90897 1.99996 4.38397 2.34996 4.08397L4.91663 1.95064C5.26663 1.65897 5.79163 1.70064 6.0833 2.05897C6.3833 2.40897 6.3333 2.93397 5.97496 3.23397ZM17.65 4.08397L15.0833 1.95064C14.7333 1.65897 14.2083 1.70064 13.9083 2.05897C13.6166 2.40897 13.6666 2.93397 14.0166 3.23397L16.575 5.3673C16.925 5.65897 17.45 5.6173 17.75 5.25897C18.05 4.90897 18 4.38397 17.65 4.08397ZM13.225 12.6173L10.4166 10.9506V7.17564C10.4166 6.8423 10.15 6.57564 9.81663 6.57564H9.76663C9.4333 6.57564 9.16663 6.8423 9.16663 7.17564V11.109C9.16663 11.4006 9.31663 11.6756 9.57496 11.8256L12.6166 13.6506C12.9 13.8173 13.2666 13.734 13.4333 13.4506C13.6083 13.159 13.5166 12.784 13.225 12.6173ZM9.99996 3.2423C5.8583 3.2423 2.49996 6.60064 2.49996 10.7423C2.49996 14.884 5.8583 18.2423 9.99996 18.2423C14.1416 18.2423 17.5 14.884 17.5 10.7423C17.5 6.60064 14.1416 3.2423 9.99996 3.2423ZM4.16663 10.7423C4.16663 13.959 6.7833 16.5756 9.99996 16.5756C13.2166 16.5756 15.8333 13.959 15.8333 10.7423C15.8333 7.52564 13.2166 4.90897 9.99996 4.90897C6.7833 4.90897 4.16663 7.52564 4.16663 10.7423Z" fill="#37657F" />
                                                        </svg>
                                                    </Box>
                                                    <Typography className="session-course-by">20 {i18nJs.t('hr')}</Typography>
                                                </Box>
                                                <Box className="icon-container">
                                                    <Box>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                            <path fillRule="evenodd" clipRule="evenodd" d="M15.8333 2.5026H12.35C12 1.53594 11.0833 0.835938 10 0.835938C8.91667 0.835938 8 1.53594 7.65 2.5026H4.16667C3.25 2.5026 2.5 3.2526 2.5 4.16927V17.5026C2.5 18.4193 3.25 19.1693 4.16667 19.1693H15.8333C16.75 19.1693 17.5 18.4193 17.5 17.5026V4.16927C17.5 3.2526 16.75 2.5026 15.8333 2.5026ZM10 2.5026C10.4583 2.5026 10.8333 2.8776 10.8333 3.33594C10.8333 3.79427 10.4583 4.16927 10 4.16927C9.54167 4.16927 9.16667 3.79427 9.16667 3.33594C9.16667 2.8776 9.54167 2.5026 10 2.5026ZM4.16667 16.6693C4.16667 17.1276 4.54167 17.5026 5 17.5026H15C15.4583 17.5026 15.8333 17.1276 15.8333 16.6693V5.0026C15.8333 4.54427 15.4583 4.16927 15 4.16927H14.1667V5.0026C14.1667 5.91927 13.4167 6.66927 12.5 6.66927H7.5C6.58333 6.66927 5.83333 5.91927 5.83333 5.0026V4.16927H5C4.54167 4.16927 4.16667 4.54427 4.16667 5.0026V16.6693Z" fill="#37657F" />
                                                        </svg>
                                                    </Box>
                                                    <Typography className="session-course-by-format">{questions.length} {i18nJs.t('Questions')}</Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={4} >
                                <Box className="btn-container">
                                    <Button onClick={() => this.onclickNavigateEdit(object.id)} className="edit-and-join-button edit-button-session" data-test-id={`edit-button-session-${index}`}>{i18nJs.t('Edit')}
                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M21.2088 5.63249C21.5988 6.02249 21.5988 6.65249 21.2088 7.04249L19.3788 8.87249L15.6288 5.12249L17.4588 3.29249C17.6456 3.10523 17.8993 3 18.1638 3C18.4283 3 18.6819 3.10523 18.8688 3.29249L21.2088 5.63249ZM3.49878 20.5025V17.4625C3.49878 17.3225 3.54878 17.2025 3.64878 17.1025L14.5588 6.19249L18.3088 9.94249L7.38878 20.8525C7.29878 20.9525 7.16878 21.0025 7.03878 21.0025H3.99878C3.71878 21.0025 3.49878 20.7825 3.49878 20.5025Z" fill="#37657F" />
                                        </svg>
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
            )
        })
    ) : (
        <Typography>{i18nJs.t('No Assessment')}</Typography>
    )  
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        return (
            <ThemeProvider theme={theme}>
                <NavigationMenu
                    id={this.props.id}
                    navigation={this.props.navigation}
                    title="Assessment test"
                >
                    <AssessmenttestListContainer>
                        <Paper elevation={0} className="paper-container">
                            <Box className="container-main">
                                <Box className="container-main-first" style={{marginBottom:'0px'}}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                        <Box className="home-search-block" >
                        
                                            <div className="w-100 position-relative">
                                                <input placeholder={i18nJs.t('Search here')}
                                                    data-test-id="serchCourseValue-list" 
                                                    value={this.state.serchAssementValue} 
                                                    onKeyUp={(e) => {
                                                        if (e.key === "Enter") this.onClickSearchModalCloseLectureAssesment()}}
                                                    onClick={() => this.onClickSearchModalOpenLectureAssesment()}
                                                    onChange={(event)=>this.serchAssementList(event)} />
                                                {this.state.searchHistory && <div className="home-search-block-contant" ref={this.state.divRef}>
                                                <div className={`${this.state.searchHistoryData.length >= 6 ? "home-category-block-content-norecord-history-length" : ""}`}>
                                                    {this.state.searchHistoryData.length ? (
                                                this.state.searchHistoryData.map((sub:string,ind:number) => {
                                                    return(
                                                    <div className='home-search-block-contant-recent-first' key={ind}>
                                                        <div className="home-search-block-contant-recent-second" >
                                                            <HistoryIconLectureAssesment/>
                                                            
                                                        <div className="home-search-block-contant-recent-second-name" onClick={() => this.onClickSearchValueLectureAssesment(sub)}>
                                                            {sub}
                                                        </div>
                                                        </div>
                                                        <div>
                                                        <IconButton onClick={() => this.selectHistoryDeleteLectureAssesment(ind)} data-test-id="icon-button-delete">
                                                            <DeleteIconLectureLectureAssesment/>
                                                        </IconButton>
                                                        </div>
                                                    </div>
                                                    )
                                                })
                                                ):(
                                                <div className="home-category-block-content-norecord-history">
                                                    <p>{i18nJs.t('No Data')}</p>
                                                </div>
                                                )}
                                            </div>
                                                <div className="home-search-btn-container">
                                                <button className="button-green-clear" onClick={() => this.clearAllHistoryLectureAssesment()}>
                                                    {i18nJs.t('Clear All')}
                                                </button>
                                                </div>
                                            </div>}
                                                </div>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box className="container-main-second">
                                    <Box className="container-main-second-scroll">
                                        <Typography className="main-title-session">{i18nJs.t('Assessment Tests')}</Typography>
                                        <Grid container spacing={3} className={this.state.isLoading || !this.resulListtarray().length ? "height-80" : ""}>
                                            {this.state.isLoading ? (
                                                <Grid item xs={12}>
                                                    <CircularProgress style={{ color: '#205e83' }} />
                                                </Grid>
                                            ) : (this.renderDataList())}
                                        </Grid>
                                    </Box>
                                </Box>
                                <Box className="container-main-third">
                                    <Grid container spacing={3} justifyContent="flex-end">
                                        <Grid item>
                                            <Button className="backButton-session">{i18nJs.t('Back')}</Button>
                                        </Grid>
                                        <Grid item>
                                            <Button className="schedule-new-session-button" data-test-id="schedule-new-session-button" onClick={() => this.redirectCreateAssessment()}>{i18nJs.t('Create New Assessment')}</Button>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>
                        </Paper>
                    </AssessmenttestListContainer>
                </NavigationMenu>
            </ThemeProvider>
        )
        // Customizable Area End
    }
}

// Customizable Area Start

const HistoryIconLectureAssesment = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path fillRule="evenodd" clipRule="evenodd" d="M4.14407 12.0015C4.14407 6.95154 8.31407 2.86154 13.4041 3.00154C18.0941 3.13154 22.0141 7.05154 22.1441 11.7415C22.2841 16.8315 18.1941 21.0015 13.1441 21.0015C11.0541 21.0015 9.14407 20.2915 7.62407 19.0915C7.15407 18.7315 7.12407 18.0215 7.54407 17.6015C7.90407 17.2415 8.46407 17.2115 8.86407 17.5215C10.0441 18.4515 11.5341 19.0015 13.1441 19.0015C17.0441 19.0015 20.1941 15.8115 20.1441 11.9015C20.0941 8.18154 16.9641 5.05154 13.2441 5.00154C9.32407 4.95154 6.14407 8.10154 6.14407 12.0015H7.93407C8.38407 12.0015 8.60407 12.5415 8.29407 12.8515L5.50407 15.6515C5.30407 15.8515 4.99407 15.8515 4.79407 15.6515L2.00407 12.8515C1.68407 12.5415 1.90407 12.0015 2.35407 12.0015H4.14407ZM12.1441 8.75154C12.1441 8.34154 12.4841 8.00154 12.8941 8.00154C13.3041 8.00154 13.6441 8.34154 13.6441 8.74154V12.1415L16.5241 13.8515C16.8741 14.0615 16.9941 14.5215 16.7841 14.8815C16.5741 15.2315 16.1141 15.3515 15.7541 15.1415L12.6341 13.2915C12.3341 13.1115 12.1441 12.7815 12.1441 12.4315V8.75154Z" fill="#37657F"/>
    </svg>
  );
  
  const DeleteIconLectureLectureAssesment = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path fillRule="evenodd" clipRule="evenodd" d="M14.79 3.29L15.5 4H18C18.55 4 19 4.45 19 5C19 5.55 18.55 6 18 6H6C5.45 6 5 5.55 5 5C5 4.45 5.45 4 6 4H8.5L9.21 3.29C9.39 3.11 9.65 3 9.91 3H14.09C14.35 3 14.61 3.11 14.79 3.29ZM6 19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V9C18 7.9 17.1 7 16 7H8C6.9 7 6 7.9 6 9V19ZM9 9H15C15.55 9 16 9.45 16 10V18C16 18.55 15.55 19 15 19H9C8.45 19 8 18.55 8 18V10C8 9.45 8.45 9 9 9Z" fill="#37657F"/>
    </svg>
  );

const AssessmenttestListContainer = styled(Box)({
    "& .MuiTypography-body1": {
        fontFamily: "Corbel V2",
    },
    "& .paper-container": {
        width: '100%',
        overflow: 'hidden'
    },
    "& .container-main": {
        height: 'calc(100vh - 110px)',
        overflowX: 'hidden',
        background: '#f6f8fa',
        padding: 40,
        "&::-webkit-scrollbar": {
            width: 4
        },"&::-webkit-scrollbar-track": {
            boxShadow: "inset 0 0 5px transparent",
            borderRadius: 2,
            background: "#D7E0E5"
        },
        "&::-webkit-scrollbar-thumb": {
            background: "#37657F"
        },
        "@media (max-width: 767px)": {
            padding: "15px",
        }
    },
    "& .container-main-first": {
        marginBottom: 40
    },
    "& .container-main-second": {
        padding: "0 0 0 24px",
        border: "1px solid #94A3B8",
        background: "#F8FAFC",
        borderRadius: 10,
        height: "400px",
        minHeight: 'calc(100vh - 370px)',
        overflowY: "hidden",
    },
    "& .container-main-second-scroll": {
        overflowY: "auto",
        padding: "0 24px 24px 0",
        height: '100%',
        "&::-webkit-scrollbar": {
            width: 4
        },"&::-webkit-scrollbar-track": {
            boxShadow: "inset 0 0 5px transparent",
            borderRadius: 2,
            background: "#D7E0E5"
        },
        "&::-webkit-scrollbar-thumb": {
            background: "#37657F"
        },
    },
    "& .main-title-session": {
        color: '#000',
        fontSize: 18,
        fontWeight: 700,
        marginTop: 24,
        marginBottom: 24
    },
    "& .session-card": {
        borderRadius: 10,
        border: '1px solid #94A3B8',
        background: '#F1F5F9',
        overflow: "hidden"
    },
    "& .image-des-contanet": {
        display: 'flex',
        alignItems: 'center',
        gap: '16px',
        "@media (max-width: 500px)": {
            flexDirection: "column",
            alignItems: 'start',
        }
    },
    "& .image-des-contanet-images": {
        width: 97,
        height: 97,
        overflow: "hidden",
        "@media (max-width: 500px)": {
            width: "100%",
            maxWidth: "100%",
            minWidth: "100%",
        },
    },
    "& .session-image": {
        width: "100%",
        height: "100%",
    },
    "& .session-course-by": {
        color: 'rgba(0, 0, 0, 0.60)',
        fontSize: 12,
        fontWeight: 700,
    },
    "& .margin-03": {
        marginBottom: 3
    },
    "& .session-course-title": {
        color: '#000',
        fontSize: 16,
        fontWeight: 700,
    },
    "& .session-course-information": {
        color: 'rgba(0, 0, 0, 0.60)',
        fontSize: 14,
        fontWeight: 400,
        marginBottom: 10
    },
    "& .icon-container-main": {
        display: 'flex',
        alignItems: 'center',
        gap: '25px',
        "@media (max-width: 380px)": {
            flexDirection: "column"
        }
    },
    "& .icon-container": {
        display: 'flex',
        alignItems: 'center',
        gap: '6px',
    },
    "& .session-course-by-format": {
        color: 'rgba(0, 0, 0, 0.60)',
        fontSize: 12,
        fontWeight: 400,
        fontFamily: 'Montserrat',
    },
    "& .btn-container": {
        display: 'flex',
        flexDirection: 'column',
        gap: 9,
        alignItems: "end",
        "@media (max-width: 502px)": {
            alignItems: "center"
        }
    },
    "& .edit-and-join-button": {
        width: 136,
        height: 44,
        borderRadius: 8,
        textTransform: "none",
        fontFamily: "Corbel V2",
        "& span": {
            fontWeight: 700,
            fontSize: 16,
        },
    },
    "& .edit-button-session": {
        background: "#CBD5E1",
        color: "#37657F",
        "& svg": {
            marginLeft: 10
        }
    },
    "& .container-main-third": {
        marginTop: "40px"
    }
    , "& .backButton-session": {
        width: 180,
        height: 50,
        borderRadius: 8,
        border: '1px solid #94A3B8',
        background: '#F1F5F9',
        textTransform: "none",
        fontFamily: "Corbel V2",
        color: "#37657F",
        "& span": {
            fontWeight: 700,
            fontSize: 16,
        },
    },
    "& .schedule-new-session-button": {
        width: 210,
        height: 50,
        borderRadius: 8,
        background: '#37657F',
        textTransform: "none",
        fontFamily: "Corbel V2",
        color: "#FFF",
        "& span": {
            fontWeight: 700,
            fontSize: 16,
        },
    },
    "& .height-80": {
        height: '80%',
        alignItems: 'center',
        textAlign: 'center',
        justifyContent: 'center',
    }
})

const AssessmentTestTextField = withStyles({
    root: {
        height: 50,
        '& .MuiOutlinedInput-input': {
            height: 50
        },
        '& .MuiOutlinedInput-root': {
            height: 50,
            color: '#205e83',
            borderRadius: 8,
            background: '#FFF',
            fontFamily: "Corbel V2",
            '& fieldset': {
                border: "1px solid #CBD5E1",
            },
            '&:hover fieldset': {
                border: '1px solid #205e83',
            },
            '&.Mui-focused fieldset': {
                border: '1px solid #205e83',
            },
        },
    },
})(TextField);
export default AssessmenttestList
// Customizable Area End
