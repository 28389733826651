import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "../../../framework/src/Utilities";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
export interface ITermsConds {
    id: number;
    account_id: number;
    amount: number;
    created_at: string;
    payment_receipt_url: string;
    course_name: string;
    course_title: string;
    transformedStatus: string;
}

// Customizable Area End
export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    classes: any
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    tabValue: string;
    age: any;
    paymentHistory: ITermsConds[];
    isloading: boolean;
    error:string | null;
    // Customizable Area End
}

interface SS {
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

export default class PaymentsController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getProfileDetailsCallId: string = ""
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
    
        this.subScribedMessages = [
          // Customizable Area Start
          getName(MessageEnum.NavigationPayLoadMessage),
          getName(MessageEnum.SessionResponseMessage),
          getName(MessageEnum.RestAPIResponceMessage),
          // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            tabValue: 'All',
            age: "Sort by",
            paymentHistory: [],
            isloading: true,
            error:null,
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
        this.getToken();
        this.getPaymentApiHistory();
    }

    getPaymentApiHistory = async() => {
        
        const header = {
          "Content-Type": configJSON.apiContentType,
        token: await getStorageData("token")
        };
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getProfileDetailsCallId = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.getPaymentHistory
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.getPaymentMethodsMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
      }

    getToken = () => {
        const message: Message = new Message(
            getName(MessageEnum.SessionRequestMessage)
        );
        this.send(message);
    };

    handleChange = (event: any, newValue: any) => {
        this.setState({ tabValue: newValue });
        
    }

    handleChange1 = (event: React.ChangeEvent<{ value: unknown }>) => {
        this.setState({ age: event.target.value as string });
    };


    transformPaymentStatus = (paymentHistory: any[]): ITermsConds[] => {
        return paymentHistory.map(payment => {
            const { attributes } = payment;
            let transformedStatus = attributes.verified;

            if (transformedStatus === "received") {
                transformedStatus = "Approved";
            } else if (transformedStatus === "pending") {
                transformedStatus = "Pending";
            } else if(transformedStatus === "declined"){
                transformedStatus = "Declined";
            }
            return {
                id: attributes.id,
                account_id: attributes.account_id,
                amount: attributes.amount,
                created_at: attributes.created_at,
                payment_receipt_url: attributes.payment_receipt.url,
                course_name: attributes.course_name,
                course_title: attributes.course_title,
                transformedStatus,
            };
        });
    };

    handlePaymentHistoryResponse = (responseJson: any, errorResponse: any) => {
        // istanbul ignore next
        if (responseJson?.data) {
            const transformedPayments = this.transformPaymentStatus(responseJson.data);
            this.setState({ paymentHistory: transformedPayments, isloading: false });
        } else if (errorResponse) {
            this.setState({ error: "Failed to fetch payment history", isloading: false });
        }
    };

    filterPayment = () => {
        // istanbul ignore next
        return this.state.paymentHistory.filter((payment: ITermsConds) => {
            if (this.state.tabValue === 'All') return true; 
            return payment.transformedStatus === this.state.tabValue;
        });
    }

    // Customizable Area End

    async receive(from: string, message: Message) {
        // Customizable Area Start
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
        if (apiRequestCallId === this.getProfileDetailsCallId) {
            this.handlePaymentHistoryResponse(responseJson, errorResponse)
        }
        // Customizable Area End
    }
}
