import React from "react";

// Customizable Area Start
import { createTheme } from "@material-ui/core/styles";
import { Link } from "react-router-dom"
import { MainLogo } from './assets';
import "../assets/css/EmailAccountLogin.web.css";
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import i18n from "../../../components/src/TranslateLanguage";
// Customizable Area End

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
const validationSchema = Yup.object({
  email: Yup.string().email(i18n.t('INVALID_EMAIL_ADDRESS')).required(i18n.t('EMAIL_IS_REQUIRED')),
  password: Yup.string().required(i18n.t('PASSWORD_IS_REQUIRED')),
});
// Customizable Area End

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
  }

  getValidationSchema = () => {
    return Yup.object().shape({
      email: Yup.string()
        .email(i18n.t('INVALID_EMAIL_ADDRESS'))
        .required(i18n.t('EMAIL_IS_REQUIRED')),
      password: Yup.string().required(i18n.t('PASSWORD_IS_REQUIRED')),
    });
  };

  render() {
    return (
      // Required for all blocks
      // Customizable Area Start
      <div className="main-wrapper">
        <div className={`${this.state.currentInterfaceLanguage === "en" ? "signin-form-wrapper" :"signin-form-wrapper ar-row-reverse"}`}>
          <div className="col-6">
            <div className="form-image">
              <div className="login-image">
                <img src={MainLogo} alt="logo" width={300} />
              </div>
            </div>
          </div>
          <div className="col-6 signup-item">
            <div className="close-btn">
              <span data-test-id="signup-item-go-back" onClick={() => this.props.navigation.navigate("Home")} >
                <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512">
                  <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
                </svg>
              </span>
            </div>
            <div className="login-form-wrapper" dir={i18n.locale === 'ar'? 'rtl' : 'ltr'}>
              <Formik
              enableReinitialize
                initialValues={this.state.initialvalue}
                validationSchema={this.getValidationSchema()}
                onSubmit={async(values, { setSubmitting }) => {
                  this.onSignin(values.email, values.password,values.checkbox);
                  setSubmitting(false);
                }}
              >
                {({ handleSubmit }) => {
                return( 
                <form onSubmit={handleSubmit} >
                  <div className="block-heading">
                    <h4>{i18n.t('WELCOME')}</h4>
                    
                    <p>{i18n.t('SIGN_IN_TO_RAFFLES')}</p>
                  </div>
                  <div className="block-content">
                    <div className="form-group">
                      <label htmlFor="email">{i18n.t('YOUR_EMAIL')}</label>
                      <Field
                        type="email"
                        id="email"
                        name="email"
                        placeholder="Raffles@.com"
                      />
                      <span style={{ color: 'red' }}>
                        <ErrorMessage name="email" component="div" className="error" />
                      </span>
                    </div>
                    <div className="form-group" style={{position:'relative'}}>
                      <label htmlFor="password">{i18n.t('PASSWORD')}</label>
                      <Field
                        type={`${this.state.inputVisible === false ? "password" : "text"}`}
                        id="password"
                        name="password"
                      />
                      <span style={i18n.locale === 'ar' ? {pointerEvents: 'none',position:'absolute',left:15}:{}} className={`${this.state.inputVisible === true ? "password active-pass" : "password"}`} >
                        <svg style={i18n.locale === 'ar' ? {position:'absolute',pointerEvents: 'all',left:0}:{}} data-test-id="show" onClick={() => this.setState({ inputVisible: true })} className="show-pass" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 640 512"><path d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L525.6 386.7c39.6-40.6 66.4-86.1 79.9-118.4c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C465.5 68.8 400.8 32 320 32c-68.2 0-125 26.3-169.3 60.8L38.8 5.1zm151 118.3C226 97.7 269.5 80 320 80c65.2 0 118.8 29.6 159.9 67.7C518.4 183.5 545 226 558.6 256c-12.6 28-36.6 66.8-70.9 100.9l-53.8-42.2c9.1-17.6 14.2-37.5 14.2-58.7c0-70.7-57.3-128-128-128c-32.2 0-61.7 11.9-84.2 31.5l-46.1-36.1zM394.9 284.2l-81.5-63.9c4.2-8.5 6.6-18.2 6.6-28.3c0-5.5-.7-10.9-2-16c.7 0 1.3 0 2 0c44.2 0 80 35.8 80 80c0 9.9-1.8 19.4-5.1 28.2zm9.4 130.3C378.8 425.4 350.7 432 320 432c-65.2 0-118.8-29.6-159.9-67.7C121.6 328.5 95 286 81.4 256c8.3-18.4 21.5-41.5 39.4-64.8L83.1 161.5C60.3 191.2 44 220.8 34.5 243.7c-3.3 7.9-3.3 16.7 0 24.6c14.9 35.7 46.2 87.7 93 131.1C174.5 443.2 239.2 480 320 480c47.8 0 89.9-12.9 126.2-32.5l-41.9-33zM192 256c0 70.7 57.3 128 128 128c13.3 0 26.1-2 38.2-5.8L302 334c-23.5-5.4-43.1-21.2-53.7-42.3l-56.1-44.2c-.2 2.8-.3 5.6-.3 8.5z" /></svg>
                        <svg style={i18n.locale === 'ar' ? {position:'absolute',pointerEvents: 'all',left:0}:{}} data-test-id="hide" onClick={() => this.setState({ inputVisible: false })} className="hide-pass" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512"><path d="M288 80c-65.2 0-118.8 29.6-159.9 67.7C89.6 183.5 63 226 49.4 256c13.6 30 40.2 72.5 78.6 108.3C169.2 402.4 222.8 432 288 432s118.8-29.6 159.9-67.7C486.4 328.5 513 286 526.6 256c-13.6-30-40.2-72.5-78.6-108.3C406.8 109.6 353.2 80 288 80zM95.4 112.6C142.5 68.8 207.2 32 288 32s145.5 36.8 192.6 80.6c46.8 43.5 78.1 95.4 93 131.1c3.3 7.9 3.3 16.7 0 24.6c-14.9 35.7-46.2 87.7-93 131.1C433.5 443.2 368.8 480 288 480s-145.5-36.8-192.6-80.6C48.6 356 17.3 304 2.5 268.3c-3.3-7.9-3.3-16.7 0-24.6C17.3 208 48.6 156 95.4 112.6zM288 336c44.2 0 80-35.8 80-80s-35.8-80-80-80c-.7 0-1.3 0-2 0c1.3 5.1 2 10.5 2 16c0 35.3-28.7 64-64 64c-5.5 0-10.9-.7-16-2c0 .7 0 1.3 0 2c0 44.2 35.8 80 80 80zm0-208a128 128 0 1 1 0 256 128 128 0 1 1 0-256z" /></svg>
                      </span>
                      <span style={{ color: 'red' }}>
                        <ErrorMessage name="password" component="div" className="error" />
                      </span>
                    </div>
                    <div className="d-flex mb-4" dir="ltr">
                      <div className="remember-text">
                        <Field
                          type="checkbox"
                          id="checkbox"
                          name="checkbox"
                          data-test-id="checkedId"
                          onClick={()=>{this.onCheckboxClick()}}
                        />
                        <label>{i18n.t('REMEMBER_ME')}</label>
                      </div>
                      <div className="forgot-password">
                        <Link to="/ForgotPassword" style={{ textDecoration: 'none' }}>
                          <span className="forget">{i18n.t('FORGOT_PASSWORD')}</span>
                        </Link>
                      </div>
                    </div>
                    <div className="form-group">
                      <button data-test-id="formSubmitBtn" type="submit" className="btn button">{i18n.t('LOGIN')}</button>
                      <span className="or">{i18n.t('OR')}</span>
                        <input type="button" data-test-id="signupRedirect" onClick={() => this.signUpRedirect()} className="btn button-outline" defaultValue={i18n.t('SIGN_UP')} />
                    </div>
                  </div>
                </form>
                )
              }}
              </Formik>
            </div>
          </div>
        </div>
      </div>
      // Customizable Area End
    );
  }
}